import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { ChannelEntityModel, ChannelQueryModel } from '@common-src/entity-model/channel-entity';

export default class ChannelService extends BaseCRUDQService<ChannelEntityModel, ChannelQueryModel> {
    constructor(baseUrl = ChannelEntityModel.baseUrl) {
        super(baseUrl, ChannelEntityModel);
    }

    // 获取消息渠道下拉列表
    static async channelTypeList():Promise<any> {
        const url = `${ChannelEntityModel.baseUrl}/embed/list`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return _.map(res.items || res, item => ({
            name: item.channelTypeName,
            value: item.channelType
        }));
    }

    // 获取飞书消息类型列表
    static async msgTypeList():Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/type/feishu/msgTypeList`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return _.map(res.items || res, item => ({
            name: item,
            value: item
        }));
    }
}
